import { default as ai_45form_45builderH32dooRgVIMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/ai-form-builder.vue?macro=true";
import { default as editYoXtIiCNWZMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as index3J2Q8kmHbUMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/index.vue?macro=true";
import { default as indexvZva9UJJOpMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexgmF0qVtgFPMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as sharehiqmfZgW4aMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as statsxxF6H6YzwYMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/stats.vue?macro=true";
import { default as submissionstrUy9dzStFMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/submissions.vue?macro=true";
import { default as showXoghTS3EebMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show.vue?macro=true";
import { default as guestOLwLLb2eu7Meta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/create/guest.vue?macro=true";
import { default as indexk032hD0YtsMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/forms/create/index.vue?macro=true";
import { default as homekJyYRG1v8YMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/home.vue?macro=true";
import { default as indexenUbty6admMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/index.vue?macro=true";
import { default as logincvTARoVQl2Meta } from "/home/forge/forms-api.marketmuscles.com/client/pages/login.vue?macro=true";
import { default as callbacke3kRh2487pMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/oauth/callback.vue?macro=true";
import { default as email9dsFHBQXpMMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/password/email.vue?macro=true";
import { default as _91token_93GzUeSQfitJMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/password/reset/[token].vue?macro=true";
import { default as pricingWADOMhBKoPMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/pricing.vue?macro=true";
import { default as privacy_45policy6sVVi64ay2Meta } from "/home/forge/forms-api.marketmuscles.com/client/pages/privacy-policy.vue?macro=true";
import { default as registerTl0IEJmjjyMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/register.vue?macro=true";
import { default as access_45tokensPwGOhLsRHTMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountyEV1j6MGNAMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/account.vue?macro=true";
import { default as adminUF4chnpgSXMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/admin.vue?macro=true";
import { default as billing9zeWd4zmK8Meta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/billing.vue?macro=true";
import { default as connectionsHpvb8wmnGBMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/connections.vue?macro=true";
import { default as indexq8GGQud52DMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/index.vue?macro=true";
import { default as passwordw7kQExNFzTMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/password.vue?macro=true";
import { default as profileIsxJqi6pdEMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/profile.vue?macro=true";
import { default as workspaceladzqzUqTdMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings/workspace.vue?macro=true";
import { default as settingseEOP4sjaTOMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/settings.vue?macro=true";
import { default as errorSP9EEfTyokMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/subscriptions/error.vue?macro=true";
import { default as successAxMdkvY2bHMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/subscriptions/success.vue?macro=true";
import { default as _91slug_93ps4gknl28XMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/templates/[slug].vue?macro=true";
import { default as indexWKvrbMqE0AMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/templates/index.vue?macro=true";
import { default as _91slug_936dzpl5XUHnMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/templates/industries/[slug].vue?macro=true";
import { default as my_45templatesSOpsHbjcTuMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/templates/my-templates.vue?macro=true";
import { default as _91slug_93ouEaNK0IeCMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/templates/types/[slug].vue?macro=true";
import { default as terms_45conditionsNnHIEf0KuiMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/terms-conditions.vue?macro=true";
import { default as update_45credentialsBIJSJMkm6kMeta } from "/home/forge/forms-api.marketmuscles.com/client/pages/update-credentials.vue?macro=true";
export default [
  {
    name: "ai-form-builder",
    path: "/ai-form-builder",
    meta: ai_45form_45builderH32dooRgVIMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/ai-form-builder.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-edit",
    path: "/forms/:slug()/edit",
    meta: editYoXtIiCNWZMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug",
    path: "/forms/:slug()",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showXoghTS3EebMeta?.name,
    path: "/forms/:slug()/show",
    meta: showXoghTS3EebMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: "forms-slug-show",
    path: "",
    redirect: indexvZva9UJJOpMeta?.redirect,
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-integrations",
    path: "integrations",
    meta: indexgmF0qVtgFPMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-share",
    path: "share",
    meta: sharehiqmfZgW4aMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-stats",
    path: "stats",
    meta: statsxxF6H6YzwYMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/stats.vue").then(m => m.default || m)
  },
  {
    name: "forms-slug-show-submissions",
    path: "submissions",
    meta: submissionstrUy9dzStFMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/[slug]/show/submissions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forms-create-guest",
    path: "/forms/create/guest",
    meta: guestOLwLLb2eu7Meta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/create/guest.vue").then(m => m.default || m)
  },
  {
    name: "forms-create",
    path: "/forms/create",
    meta: indexk032hD0YtsMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    meta: homekJyYRG1v8YMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logincvTARoVQl2Meta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "oauth-callback",
    path: "/oauth/callback",
    alias: ["/oauth/:provider/callback"],
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: "password-email",
    path: "/password/email",
    meta: email9dsFHBQXpMMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/password/email.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-token",
    path: "/password/reset/:token()",
    meta: _91token_93GzUeSQfitJMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingWADOMhBKoPMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policy6sVVi64ay2Meta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    meta: registerTl0IEJmjjyMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingseEOP4sjaTOMeta?.name,
    path: "/settings",
    meta: settingseEOP4sjaTOMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-access-tokens",
    path: "access-tokens",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: "settings-account",
    path: "account",
    meta: accountyEV1j6MGNAMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: "settings-admin",
    path: "admin",
    meta: adminUF4chnpgSXMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: "settings-billing",
    path: "billing",
    meta: billing9zeWd4zmK8Meta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: "settings-connections",
    path: "connections",
    meta: connectionsHpvb8wmnGBMeta || {},
    alias: ["/settings/connections/callback/:service"],
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "",
    redirect: indexq8GGQud52DMeta?.redirect,
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-password",
    path: "password",
    meta: passwordw7kQExNFzTMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: "settings-profile",
    path: "profile",
    meta: profileIsxJqi6pdEMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: "settings-workspace",
    path: "workspace",
    meta: workspaceladzqzUqTdMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "subscriptions-error",
    path: "/subscriptions/error",
    meta: errorSP9EEfTyokMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-success",
    path: "/subscriptions/success",
    meta: successAxMdkvY2bHMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: "templates-slug",
    path: "/templates/:slug()",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates",
    path: "/templates",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/templates/index.vue").then(m => m.default || m)
  },
  {
    name: "templates-industries-slug",
    path: "/templates/industries/:slug()",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: "templates-my-templates",
    path: "/templates/my-templates",
    meta: my_45templatesSOpsHbjcTuMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/templates/my-templates.vue").then(m => m.default || m)
  },
  {
    name: "templates-types-slug",
    path: "/templates/types/:slug()",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: "terms-conditions",
    path: "/terms-conditions",
    meta: terms_45conditionsNnHIEf0KuiMeta || {},
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: "update-credentials",
    path: "/update-credentials",
    component: () => import("/home/forge/forms-api.marketmuscles.com/client/pages/update-credentials.vue").then(m => m.default || m)
  }
]